import React from "react";

const StatusBadge = ({ status }) => {
  return status === "1" ? (
    <span className="badge bg-warning">Pending</span>
  ) : status === "2" ? (
    <span className="badge bg-warning">DPA Review</span>
  ) : status === "3" ? (
    <span className="badge bg-info">Verified</span>
  ) : status === "4" ? (
    <span className="badge bg-warning">Nodal Officer Review</span>
  ) : status === "5" ? (
    <span className="badge bg-primary">Approved</span>
  ) : status === "6" ? (
    <span className="badge bg-primary">Final Approved</span>
  ) : status === "7" ? (
    <span className="badge bg-danger">Rejected</span>
  ) : status === "8" ? (
    <span className="badge bg-warning">Under Process</span>
  ) : status === "9" ? (
    <span className="badge bg-success">Released</span>
  ) : (
    ""
  );
};

export default StatusBadge;
