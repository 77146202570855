import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../Config/Api";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import OTPInput from "otp-input-react";
import { useForm, Controller } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";

const ApplicationPayment = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const {
    control,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  const [otpModalBox, setOtpModalBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentModeData, setPaymentModeData] = useState(null);
  const [paymentOption] = useState(["Bank Transfer", "Cash", "Other"]);
  const [paymentOtherOption] = useState([
    "PhonePe",
    "Google Pay",
    "Paytm",
    "Amazon Pay",
  ]);

  const otpModalShow = () => {
    setOtpModalBox(true);
  };
  const otpModalClose = () => {
    setOtpModalBox(false);
  };

  //OTP Modal
  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      const body = {
        id: data.data.id,
        payment_mode: d?.payment_option,
      };
      // console.log(d);
      if (d?.payment_option === "Other" && Boolean(d?.other)) {
        body.payment_type_other = d?.other;
      }
      // console.log(body);

      const res = await Api("send-payment-otp", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        otpModalShow();
        setPaymentModeData(res.data.data);
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      } else if (res.data.status === 2) {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //OTP Modal
  // console.log(paymentModeData);
  //onlinePayment
  // const onlinePayment = async () => {
  //   // console.log("bank");
  //   try {
  //     const body1 = {
  //       id: data.data.id,
  //     };
  //     const res = await Api("razorpay-contact", "POST", body1);
  //     let contact_id = JSON.parse(res.data.data).id;
  //     // console.log(res);
  //     if (res.data.status === "success") {
  //       const body2 = {
  //         id: data.data.id,
  //         contact_id,
  //       };
  //       const res = await Api("razorpay-fund_accounts", "POST", body2);
  //       const fund_account_id = JSON.parse(res.data.data).id;
  //       if (res.data.status === "success") {
  //         const body3 = {
  //           id: data.data.id,
  //           fund_account_id,
  //         };
  //         const res = await Api("razorpay-payouts", "POST", body3);
  //         if (res.data.status === "success") {
  //           otpModalClose();
  //           navigate("/application/thankyou", {
  //             state: location.state,
  //             replace: true,
  //           });
  //           return true;
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     toast.error(error.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //   }
  // };

  const onlinePayment = async () => {
    try {
      const body = {
        id: data.data.id,
      };
      const res = await Api("payout", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        otpModalClose();
        navigate("/application/thankyou", {
          state: location.state,
          replace: true,
        });
        return true;
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //onlinePayment

  //Verify Modal
  const onOtpSubmit = async (d) => {
    try {
      setIsLoading(true);
      const body = {
        id: data.data.id,
        payment_otp: d.otp,
      };
      const res = await Api("verify-payment-otp", "POST", body);
      // console.log(res);
      if (res.data.status === "success") {
        if (paymentModeData?.payment_mode === "Bank Transfer") {
          const res = await onlinePayment();
          if (res) {
            toast.success("Payment Sent Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setIsLoading(false);
          }
        } else {
          otpModalClose();
          navigate("/application/thankyou", {
            state: location.state,
            replace: true,
          });
          setIsLoading(false);
        }
      } else {
        toast.error("Invalid OTP", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Verify Modal

  useEffect(() => {
    // console.log(data);
    if (!data) {
      navigate("/applications");
      // toast.error("Something went wrong!", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
    //eslint-disable-next-line
  }, []);
  // console.log('navigate',navigate);
  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div>
        <button className="btn btn-primary mb-2" onClick={()=>navigate(-1)}><FaArrowLeft/> Back</button>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="border p-4 rounded-4">
                <h5 className="text-dark text-center mb-4">Payment Option</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row g-2 g-lg-3">
                    <div className="col-12">
                      <div className="">
                        <label className="form-label">
                          Select Payment Option
                        </label>
                        <div className="d-flex flex-wrap gap-2">
                          {paymentOption?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="bg-light flex-grow-1 px-3 py-2 rounded"
                              >
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      {...register("payment_option", {
                                        required: true,
                                      })}
                                      disabled={isLoading}
                                      value={item}
                                    />
                                    {item}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {errors.payment_option && (
                          <div className="error_line">
                            Payment Option is required
                          </div>
                        )}
                      </div>
                    </div>
                    {watch("payment_option") === "Other" && (
                      <div className="col-12">
                        <div className="">
                          <label className="form-label">Select the mode</label>
                          <div className="d-flex flex-wrap gap-2">
                            {paymentOtherOption?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="bg-light flex-grow-1 px-3 py-2 rounded"
                                >
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        {...register("other", {
                                          required: true,
                                        })}
                                        disabled={isLoading}
                                        value={item}
                                      />
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {errors.other && (
                            <div className="error_line">
                              Payment Mode is required
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-12">
                      <div className="text-center">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className="btn btn-success px-4"
                        >
                          {isLoading ? "Please Wait..." : "Send Money"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={otpModalBox}
        onHide={otpModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit1(onOtpSubmit)}>
            <div className="p-3">
              <div className="row g-3 justify-content-center">
                <div className="col-12">
                  <div className="text-center text-dark">
                    <h6 className="m-0">Please enter OTP </h6>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <div className="otpbox">
                      <Controller
                        control={control}
                        name="otp"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <OTPInput
                            value={value || ""}
                            onChange={(d) => onChange(d)}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            isDisabled={isLoading}
                          />
                        )}
                      />
                      {errors1.otp && (
                        <div className="error_line text-center">
                          OTP is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplicationPayment;
