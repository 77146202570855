import React, { useEffect, useState, useRef } from "react";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { useForm } from "react-hook-form";
import Loader from "../Elements/Loader";
import Table from "../Elements/Table";
import TooltipWidget from "../Elements/TooltipWidget";
import dayjs from "dayjs";
import { BsFillEyeFill } from "react-icons/bs";
import ModalBox from "../Elements/ModalBox";
import ReactToPrint from "react-to-print";
import { FaPrint } from "react-icons/fa";

const PaymentHistory = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [isdetailModalShow, setIsdetailModalShow] = useState(false);
  const [detailModalData, setDetailModalData] = useState("");
  const componentRef = useRef();

  const detailModalShow = (d) => {
    //  console.log('gelo',d);
    setDetailModalData(d);
    setIsdetailModalShow(true);
  };
  const detailModalClose = () => {
    setDetailModalData("");
    setIsdetailModalShow(false);
  };

  //Application Get
  const getApplication = async () => {
    try {
      const res = await Api("payment_history");
      // console.log(res.data);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setIsApplicationLoaded(true);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsApplicationLoaded(true);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Application Get

  useEffect(() => {
    getApplication();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (props) => application.indexOf(props) + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "Application ID",
      selector: (row) => (!!row.application_no ? row.application_no : "-"),
      sortable: true,
      wrap: true,
    },
    {
      //   name: "Applicant Image",
      name: "Applicant Passport Photo",
      selector: (row) => (
        <img
          src={row.application?.applicant_default_img}
          alt="Applicant"
          style={{
            height: "70px",
            width: "100px",
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      name: "Payment Type",
      selector: (row) =>
        !!row.application?.payment_mode ? row.application?.payment_mode : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Payment Type Other",
      selector: (row) =>
        !!row.application?.payment_type_other
          ? row.application?.payment_type_other
          : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      wrap: true,
      sortFunction: (a, b) => a?.amount - b?.amount,
    },
    {
      name: "Reference ID",
      selector: (row) => row.reference_id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Payout ID",
      selector: (row) => row.payout_id,
      sortable: true,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => dayjs(row.created_at).format("DD MMM YYYY hh:mm A"),
      sortable: true,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "Action",
      selector: (row) => {
        // console.log('row',row)
        return (
          <>
            <div>
              <TooltipWidget title="View">
                <button
                  className="btn btn-warning btn-sm"
                  type="button"
                  onClick={() => detailModalShow(row)}
                >
                  <BsFillEyeFill />
                </button>
              </TooltipWidget>
            </div>
          </>
        );
      },
      sortable: true,
      wrap: true,
      width: "70px",
    },
  ];

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-2">
          <div className="row g-2">
            <div className="col-6 col-lg-4 col-md-4">
              <h5 className="text-dark">Payment History</h5>
            </div>
          </div>
        </div>
        <div>
          {isApplicationLoaded ? (
            <Table columns={columns} data={application} />
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <ModalBox
        show={isdetailModalShow}
        hide={detailModalClose}
        title="Payment Detail"
        size="lg"
      >
        <ReactToPrint
          trigger={() => (
            <div className="text-end mb-3">
              <button type="button" className="btn btn-success btn-sm px-2">
                <FaPrint /> Print
              </button>
            </div>
          )}
          content={() => componentRef.current}
        />
        <div ref={componentRef}>
          <div className="row g-2">
            <div className="col-12">
              <div className="">
                <img
                  src={detailModalData?.application?.applicant_default_img}
                  alt="Applicant Img"
                  style={{
                    height: "90px",
                    width: "90px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Application ID</label>
                <p>{detailModalData?.application_no}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Name</label>
                <p>{detailModalData?.account_holder_name}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Email</label>
                <p>{detailModalData?.email}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Contact Number</label>
                <p>{detailModalData?.contact}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Amount</label>
                <p>{detailModalData?.amount}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Account Number</label>
                <p>{detailModalData?.account_no}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">IFSC</label>
                <p>{detailModalData?.ifsc}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Payment Type</label>
                <p>
                  {!!detailModalData?.application?.payment_mode
                    ? detailModalData?.application?.payment_mode
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Payment Type Other</label>
                <p>
                  {!!detailModalData?.application?.payment_type_other
                    ? detailModalData?.application?.payment_type_other
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Reference ID</label>
                <p>{detailModalData?.reference_id}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Payout ID</label>
                <p>{detailModalData?.payout_id}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Status</label>
                <p>{detailModalData?.status}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Date</label>
                <p>
                  {dayjs(detailModalData?.created_at).format(
                    "DD MMM YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    </>
  );
};

export default PaymentHistory;
